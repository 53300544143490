import ButtonLink from '@components/ButtonLink';
import { Colors, Text, Title } from '@components/global.styles';
import * as React from 'react';
import { Fragment } from 'react';
import { Row } from 'shards-react';
import { Containe, Content } from './styles';

interface IntroductionProps {
  name: string;
}

const Question: React.FC<IntroductionProps> = ({ name }) => {
  return (
    <Fragment>
      <Containe>
        <Row>
          <Content>
            <Title
              className="op1 big-text"
              style={{ marginTop: '2rem' }}
              colors={Colors.gray}
            >
              {name}.
            </Title>

            <Text
              className="small-text"
              colors={Colors.gray}
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                display: 'flex',
                marginTop: '4rem',
              }}
            >
              Dacă aveți întrebări, vă rugăm să le puneți în formularul de mai
              jos. Vă mulțumim!
            </Text>
            <br />
            <div
              className="content-button01"
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLScnr1X-fmzKe9gvbhvaDikFdsHdhmk1kCmRk434A2JeeW4ZCw/viewform?embedded=true"
                width="640"
                height="600"
                style={{ border: 0 }}
              >
                Loading…
              </iframe>
              ;
            </div>
          </Content>
        </Row>
      </Containe>
    </Fragment>
  );
};

export default Question;
