import GlobalLayout from '@layouts/index';
import Question from '@sections/Questions';
import * as React from 'react';

const IndexPage = (props: any) => {
  return (
    <GlobalLayout {...props}>
      <Question name="Întrebări?" />
    </GlobalLayout>
  );
};

export default IndexPage;
